@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

body {
	@include font-size($font-size-body-md);
	font-family: $font-family-body;
	font-weight: $font-weight-regular;

	text-rendering: geometricPrecision;
	-moz-text-rendering: geometricPrecision;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

i {
	font-style: italic;
}

b {
	font-weight: $font-weight-semibold;
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4,
.h5 {
	font-family: $font-family-heading;
	letter-spacing: $letter-spacing-heading-tight;
	font-weight: $font-weight-semibold;
}

p,
.lead,
.paragraph,
.paragraph--sm,
.paragraph--xs,
.paragraph--xxs,
.paragraph--lg {
	font-family: $font-family-body;
	letter-spacing: $letter-spacing-body;
	font-weight: $font-weight-regular;
}

.lead {
	font-weight: $font-weight-medium;
}

h5,
.h5 {
	font-family: $font-family-body;
	letter-spacing: $letter-spacing-heading-loose;
	font-weight: $font-weight-semibold;
}

h1,
.h1 {
	@include font-size($font-size-h1);
	line-height: $line-height-h1;
}

h2,
.h2 {
	@include font-size($font-size-h2);
	line-height: $line-height-h2;
}

h3,
.h3 {
	@include font-size($font-size-h3);
	line-height: $line-height-h3;
}

h4,
.h4 {
	@include font-size($font-size-h4);
	line-height: $line-height-h4;
}

h5,
.h5 {
	@include font-size($font-size-h5);
	line-height: $line-height-h5;
}

.paragraph {
	@include font-size($font-size-body-md);
	line-height: $line-height-body;
}

.paragraph--sm {
	@include font-size($font-size-body-sm);
	line-height: $line-height-body;
}

.paragraph--xs {
	@include font-size($font-size-body-xs);
	line-height: $line-height-body;
}

.paragraph--xxs {
	@include font-size($font-size-body-xxs);
	line-height: $line-height-body;
}

.lead,
.paragraph--lg {
	@include font-size($font-size-body-lg);
	line-height: $line-height-body-lg;
}

.text--centered {
	text-align: center;
}

.text--secondary {
	color: $color-text-secondary;
}

.text--accent {
	color: $color-accent;
}

.text--faded {
	opacity: 0.33;
}

.text--positive {
	color: $color-positive;
}

.text--negative {
	color: $color-negative;
}

.text--semibold {
	font-weight: $font-weight-semibold;
}

.text--medium {
	font-weight: $font-weight-medium;
}

p {
	margin: 0;
}
