@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

$spacing: (
	none: (
		null: 0,
	),
	xxs: (
		null: 0.25rem,
	),
	xs: (
		null: 0.5rem,
	),
	sm: (
		null: 0.75rem,
	),
	md: (
		null: 0.75rem,
		lg: 1rem,
	),
	lg: (
		null: 1rem,
		lg: 1.5rem,
	),
	xl: (
		null: 1.5rem,
		lg: 2rem,
	),
	xxl: (
		null: 1.5rem,
		lg: 2.5rem,
	),
	xxxl: (
		null: 2rem,
		lg: 4rem,
	),
	xxxxl: (
		null: 2rem,
		lg: 5rem,
	),
);

@mixin spacing-generator($spacing-map, $property) {
	@each $size, $values in $spacing-map {
		$default: map-get($values, null);

		// Generate base class
		.#{$property}--#{$size} {
			#{$property}: #{$default} !important;
		}

		// Generate classes for breakpoints
		@each $breakpoint, $value in $values {
			@if $breakpoint != null {
				.#{$property}--#{$size} {
					@include media-breakpoint-up($breakpoint) {
						#{$property}: #{$value} !important;
					}
				}
			}
		}
	}
}

@include spacing-generator($spacing, "margin-top");
@include spacing-generator($spacing, "margin-bottom");
@include spacing-generator($spacing, "margin-left");
@include spacing-generator($spacing, "margin-right");
@include spacing-generator($spacing, "padding-top");
@include spacing-generator($spacing, "padding-bottom");
@include spacing-generator($spacing, "padding-left");
@include spacing-generator($spacing, "padding-right");

.margin-horizontal--auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

.margin-vertical--auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}
