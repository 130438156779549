@use "../abstracts/variables" as *;

html,
body {
	margin: 0;
	padding: 0;
}

body {
	background-color: $color-background;
	color: $color-text;
	position: relative;
}

*,
::before,
::after {
	box-sizing: border-box;
}
