@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.button {
	@include font-size($font-size-body-md);
	@include focus-ring($border-radius-button-md);
	@include float-on-hover();

	display: flex;
	padding: 0.5rem 1.5rem;
	font-family: $font-family-body;
	font-weight: $font-weight-medium;
	line-height: $line-height-body;
	color: $color-text;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	user-select: none;
	box-shadow: inset 0 0 0 $border-width-default $color-border;
	border-radius: $border-radius-button-md;
	min-height: $button-size-md;
	background: white;
	align-items: center;
	border: none;
	appearance: none;
	position: relative;
	text-decoration: none;

	&:hover {
		box-shadow: inset 0 0 0 $border-width-emphasized $color-border-strong;
	}

	&:active {
		filter: brightness(0.9);
	}

	svg {
		margin-left: -0.125rem;
		margin-right: 0.25rem;
	}
}

.button--sm {
	@include focus-ring($border-radius-button-sm);

	padding: 0.5rem 1rem;
	border-radius: $border-radius-button-sm;
	min-height: $button-size-sm;
}

.button--xs {
	@include font-size($font-size-body-sm);
	@include focus-ring($border-radius-button-xs);

	padding: 0.25rem 0.75rem;
	border-radius: $border-radius-button-xs;
	min-height: $button-size-xs;
}

.button--icon {
	align-items: center;
	justify-content: center;
	height: $button-size-md;
	width: $button-size-md;
	padding: 0;

	&.button--sm {
		height: $button-size-sm;
		width: $button-size-sm;
	}

	&.button--xs {
		height: $button-size-xs;
		width: $button-size-xs;
	}

	svg {
		margin: 0;
	}
}

.button--primary {
	color: $color-text-inverted;
	background: $color-accent;
	box-shadow: none;

	&:hover {
		box-shadow: none;
		filter: brightness(1.1);
	}
}

.button--transparent {
	color: $color-text;
	background: transparent;
	box-shadow: none;

	&:hover {
		box-shadow: none;
		background: $color-background-level3;
		filter: brightness(1.1);
	}
}

.button--active {
	border-style: solid;
	border-color: $color-accent;
	border-width: $border-width-emphasized;
}

.button--floating {
	background: $color-background;
	box-shadow: $shadow-sm;
}

.button--dropdown {
	padding-right: 2rem;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
	background-size: 1rem;
	background-position: right 0.75rem center;
	background-repeat: no-repeat;
}

.button--inverted {
	background: $color-text;
	color: $color-text-inverted;
}
