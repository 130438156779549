@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.rating {
	display: flex;
	align-items: flex-end;
	gap: 1rem;
}

.rating__box {
	@include font-size($font-size-h3);
	line-height: 1;
	font-weight: $font-weight-semibold;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	width: 7rem;
	height: 7rem;
	padding: 0.75rem;

	border-width: $border-width-emphasized;
	border-color: $color-border;
	border-style: solid;
	color: $color-text-secondary;
	border-radius: $border-radius-card;
}

.rating__summary {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	max-width: 8.5rem;
}

.rating--vertical {
	flex-direction: column;
	align-items: flex-end;
	gap: 0.25rem;
}

.rating--compact {
	.rating__box {
		@include font-size($font-size-h5);
		width: 3.5rem;
		height: 3.5rem;
		padding: 0.25rem;
	}
	.rating__summary {
		gap: 0;
	}
}

.rating--positive {
	.rating__box {
		border-color: $color-positive-faded;
		color: $color-positive;
	}

	.rating__keyword {
		color: $color-positive;
	}
}

.rating--mediocre {
	.rating__box {
		border-color: $color-border;
		color: $color-text-secondary;
	}

	.rating__keyword {
		color: $color-text-secondary;
	}
}

.rating--negative {
	.rating__box {
		border-color: $color-negative-faded;
		color: $color-negative;
	}

	.rating__keyword {
		color: $color-negative;
	}
}
