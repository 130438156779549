@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.filters {
	padding: 2rem 0 1rem;
	width: 15%;
	position: sticky;
	top: $navigation-height;
	max-height: calc(100dvh - $navigation-height);
	overflow: auto;

	@include media-breakpoint-down(md) {
		position: fixed;
		top: auto;
		bottom: 0;
		left: 0;
		z-index: $z-index-filters-mobile;
		width: calc(100dvw - 1rem * 2);
		max-height: calc(100dvh - 1rem * 2);
		margin: 1rem;
		padding: 1rem;
		background-color: $color-background;
		border-radius: $border-radius-section;
		box-shadow: $shadow-lg, $shadow-lg, $shadow-lg;
		visibility: hidden;
		opacity: 0;
		transform: translate3d(0, 100%, 0);
		transition: all 0.2s ease;
		overflow: auto;

		&.filters--open {
			visibility: visible;
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition: all 0.66s $ease-in-out-quint;
		}
	}
}

.filters__header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.75rem;

	@include media-breakpoint-down(md) {
		padding-top: 2rem;
	}
}

.filters__count {
	line-height: $line-height-body;
}

.filters--hidden {
	display: none;
}

.filters__section {
	margin-top: 1.5rem;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 0.5rem;
}

.filters__section--vertical {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.filters__section__divider {
	font-weight: $font-weight-medium;
	margin-bottom: 0.5rem;
	color: $color-text-secondary;
	width: 100%;
}

.filters__close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	display: none;

	@include media-breakpoint-down(md) {
		display: flex;
	}
}

.filters__apply {
	display: none;

	@include media-breakpoint-down(md) {
		display: flex;
		margin-top: 3rem;
		width: 100%;
		justify-content: center;
	}
}

.filter-button {
	@include focus-ring($border-radius-tag);
	border: none;
	display: block;

	.filter-button__label {
		@include float-on-hover();
		cursor: pointer;
		display: block;
		border-radius: $border-radius-tag;
		background: $color-background-level1;
		padding: 0.25rem 0.5rem;
		transition: padding 0.2s ease, background-size 0.2s ease;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
		background-size: 0rem;
		background-position: left 0.5rem center;
		background-repeat: no-repeat;
	}

	input[type="checkbox"] {
		appearance: none;
		-webkit-appearance: none;
		display: block;
	}

	&:hover .filter-button__label {
		filter: brightness(0.9);
	}

	&:active .filter-button__label {
		filter: brightness(0.8);
	}

	input[type="checkbox"]:checked + .filter-button__label {
		background-color: $color-background-level3;
		padding-left: 1.75rem;
		background-size: 1rem;
	}
}
