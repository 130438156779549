@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.badge {
	@include font-size($font-size-body-md);
	white-space: nowrap;
}

.badge__dot {
	flex: none;
	display: inline-block;
	width: 0.5em;
	height: 0.5em;
	vertical-align: middle;
	margin-bottom: 0.2em;
	border-radius: 50%;
	background: $color-background-level3;
	margin-right: 0.5em;
}

.badge--sm {
	@include font-size($font-size-body-sm);
}
