@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;
@use "../layout/clinic-card" as *;
@use "../layout/treatment-card" as *;
@use "../components/list" as *;

@mixin skeleton-text-size(
	$fs-map,
	$line-height,
	$fs-breakpoints: $grid-breakpoints
) {
	@each $fs-breakpoint, $fs-font-size in $fs-map {
		@if $fs-breakpoint == null {
			height: $fs-font-size * $line-height;
			width: $fs-font-size * 5;
		} @else {
			// If $fs-font-size is a key that exists in
			// $fs-breakpoints, use the value
			@if map-has-key($fs-breakpoints, $fs-breakpoint) {
				$fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
			}
			@media screen and (min-width: $fs-breakpoint) {
				height: $fs-font-size * $line-height;
				width: $fs-font-size * 5;
			}
		}
	}
}

.skeleton-box {
	@include shimmer();
	border-radius: $border-radius-card;
}

.clinics-list__skeleton {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;

	.skeleton-box {
		@extend .clinic-card__inner;
	}
}

.clinic__skeleton {
	.clinic__body,
	.clinic__panel {
		height: 25rem;
	}
}

.treatments-list__skeleton {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	width: 100%;

	.skeleton-box {
		@extend .treatment-card;
	}
}

.list__skeleton {
	width: 100%;

	.skeleton-box {
		height: 2rem;
		width: 100%;
		margin-bottom: 0.125rem;
	}
}

.skeleton-box {
	&.h1 {
		@include skeleton-text-size($font-size-h1, $line-height-h1);
	}

	&.h2 {
		@include skeleton-text-size($font-size-h2, $line-height-h2);
	}

	&.h3 {
		@include skeleton-text-size($font-size-h3, $line-height-h3);
	}

	&.h4 {
		@include skeleton-text-size($font-size-h4, $line-height-h4);
	}

	&.h5 {
		@include skeleton-text-size($font-size-h5, $line-height-h5);
	}

	&.paragraph,
	&.paragraph--md {
		@include skeleton-text-size($font-size-body-md, $line-height-body);
	}

	&.paragraph--sm {
		@include skeleton-text-size($font-size-body-sm, $line-height-body);
	}

	&.paragraph--xs {
		@include skeleton-text-size($font-size-body-xs, $line-height-body);
	}

	&.paragraph--xxs {
		@include skeleton-text-size($font-size-body-xxs, $line-height-body);
	}

	&.lead,
	&.paragraph--lg {
		@include skeleton-text-size($font-size-body-lg, $line-height-body-lg);
	}
}
