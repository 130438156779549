@use "../abstracts/variables" as *;

$card-transition-timeout: 400ms;
$toast-transition-timeout: 600ms;

.card-transition {
	&-enter {
		opacity: 0 !important;
	}
	&-enter-active {
		opacity: 1 !important;
		transition: all $card-transition-timeout ease !important;
	}
	&-exit {
		transform: scale(1) !important;
		opacity: 1 !important;
	}
	&-exit-active {
		opacity: 0 !important;
		transform: scale(0.98) !important;
		transition: all 0.2s ease !important;
	}

	@for $i from 1 through 5 {
		&-enter-active:nth-child(#{$i}) {
			transition-delay: $i * 0.1s !important;
		}
	}
}

.toast-transition {
	&-enter {
		transform: translate3D(100%, 0, 0);
		opacity: 0;
	}
	&-enter-active {
		opacity: 1;
		transform: translate3D(0, 0, 0);
		transition: all $toast-transition-timeout $ease-out-quint;
	}
	&-exit {
		transform: scale(1);
		opacity: 1;
	}
	&-exit-active {
		opacity: 0;
		transform: translate3D(0, -2rem, 0) scale(0.85);
		transition: all 0.2s ease;
	}
}
