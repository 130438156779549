@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.destinations-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 0.5rem;
	grid-row-gap: 0.5rem;
}

.destination-card {
	min-width: 15rem;
	appearance: none;
	color: $color-white;
	text-decoration: none;
	border: none;
	background-color: gray;
	background-size: cover;
	padding: 1.5rem;
	gap: 0.5rem;
	justify-content: space-between;
	height: 20rem;
	transition-property: transform, filter;

	&:hover {
		filter: brightness(1.2);
	}

	&:active {
		filter: brightness(0.8);
	}

	.badge {
		color: $color-white;
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: $border-radius-card;
		background: linear-gradient(
			180deg,
			rgba($color-black, 0.2) 0%,
			rgba($color-black, 0.7) 100%
		);
	}

	.badge__dot {
		filter: hue-rotate(180deg) invert(1);
	}
}

.destination-card__top,
.destination-card__bottom {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.5rem;
	padding-right: 2rem;
	position: relative;
	z-index: 1;
}

.destination-card__bottom {
	@include font-size($font-size-body-sm);
	color: $color-text-secondary;
}

.destination-card__countries {
	display: flex;
	gap: 0.25rem;
}
