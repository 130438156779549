@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.clinics-list {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 12%;
}

.clinics-list__main {
	flex: 1;
}

.clinics-list__results {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	min-height: 20rem;
}

.list__toolbar {
	display: flex;
	justify-content: space-between;
	margin: 2rem auto 2rem;
	width: 100%;
}

.list__toolbar__switch {
	display: flex;
	gap: 0.5rem;

	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.list__toolbar__filterbutton {
	display: none;

	@include media-breakpoint-down(md) {
		display: flex;
	}
}

.clinic-card {
	width: 100%;
	position: relative;
}

.clinic-card__inner {
	width: 100%;

	appearance: none;
	color: $color-text;
	text-decoration: none;
	flex-direction: row;
	align-items: stretch;
	overflow: hidden;

	padding: 0;
	display: flex;
	height: 19rem;
}

.clinic-card__leading {
	width: 20rem;
	padding: $card-base-padding;
	position: relative;
}

.clinic-card__image {
	width: 100%;
	height: 100%;
	display: block;
	object-fit: cover;
	background: $color-background-level2;
	border-radius: $border-radius-card-interior;
}

.clinic-card__image__head {
	position: absolute;
	bottom: 1.25rem;
	left: 1.25rem;
	color: $color-white;
	display: none;
	flex-direction: column;
	gap: 0.25rem;
}

.clinic-card__buttons {
	display: flex;
	gap: 0.5rem;
	position: absolute;
	bottom: 1.25rem;
	left: 1.25rem;
}

.clinic-card__main,
.clinic-card__trailing {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 2rem;
}

.clinic-card__main {
	flex: 1;
}

.clinic-card__main__head {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.clinic-card__title {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
}

.treatment-card__bottom {
	@include font-size($font-size-body-sm);
	color: $color-text-secondary;
}

.clinic-card__accreditations {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	flex-grow: 1;
}

.clinic-card__highlight,
.clinic-card__accreditation {
	display: flex;
	align-items: center;
	margin-top: 0.25rem;

	svg {
		margin-right: 0.5rem;
		opacity: 0.5;
	}
}

.clinic-card__trailing {
	align-items: flex-end;
	text-align: right;
	width: 12.5rem;
	background: $color-background-level1;
}

.clinic-card .rating {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
	flex: 1;
	margin-bottom: 0.5rem;
}

.clinic-card__value {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 0.5rem;
	width: 100%;
}

.clinic-card__treatments-list {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	overflow: hidden;
	align-self: stretch;
}

.clinic-card .badge {
	flex: none;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.clinic-card .badge__dot {
	order: 2;
	margin-right: 0;
	margin-left: 0.5rem;
	float: right;
	margin-top: 0.4em;
}

@mixin clinic-card-compact {
	min-width: 20rem;
	max-width: 24rem;
	flex: 1;

	@include media-breakpoint-down(lg) {
		max-width: none;
	}

	.clinic-card__buttons {
		bottom: auto;
		top: 1.25rem;
	}

	.clinic-card__inner {
		height: 31rem;
		flex-direction: column;

		@include media-breakpoint-down(md) {
			height: 24rem;
		}
	}

	.clinic-card__leading {
		width: 100%;
		height: 17.5rem;

		@include media-breakpoint-down(md) {
			height: 12rem;
		}
	}

	.clinic-card__leading::before {
		content: "";
		position: absolute;
		top: $card-base-padding;
		left: $card-base-padding;
		right: $card-base-padding;
		bottom: $card-base-padding;
		border-radius: $border-radius-card-interior;
		background: linear-gradient(
			to top,
			rgba(0, 0, 0, 0.75) 0%,
			rgba(0, 0, 0, 0.5) 50%,
			rgba(0, 0, 0, 0) 100%
		);
	}

	.clinic-card__main {
		padding: 0.5rem 1.25rem 1rem;
	}

	.clinic-card__image__head {
		display: flex;
	}

	.clinic-card__main__head {
		display: none;
	}

	.clinic-card__trailing {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 1.25rem;

		align-items: center;
		text-align: right;
		width: 100%;
		height: 5rem;
	}

	.rating {
		flex-direction: row;
		align-items: center;
		margin: 0;
		text-align: center;
		gap: 0.5rem;
	}

	.rating__box {
		@include font-size($font-size-body-lg);
		width: 3rem;
		height: 3rem;
	}

	.rating__summary {
		align-items: flex-start;
	}

	.rating__keyword {
		order: 2;
	}

	.clinic-card__treatment-info {
		display: flex;
		flex-direction: row;
	}

	.clinic-card__value {
		margin-top: 0;
		margin-left: 1rem;
	}
}

.clinic-card--compact {
	@include clinic-card-compact();
}

.clinic-card {
	@include media-breakpoint-down(lg) {
		@include clinic-card-compact();
	}
}

.clinic__map__placeholder {
	display: flex;
	align-items: center;
	justify-content: center;
}

.clinics-list__empty {
	width: 100%;
	border: none;
	background-color: $color-background-level1;
}
