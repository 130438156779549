@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.search {
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: left;
	position: relative;

	@include media-breakpoint-up(md) {
		z-index: $z-index-search;
	}

	@include media-breakpoint-down(sm) {
		height: auto !important;
	}
}

.search__inner {
	display: flex;
	color: $color-text;
	background: $color-background-floating;
	border-radius: $border-radius-card + $card-base-padding;
	align-items: center;
	width: 100%;
	max-width: $search-field-width;
	box-shadow: $shadow-lg;
	position: relative;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		align-items: stretch;
	}
}

.search--fixed {
	@include media-breakpoint-up(md) {
		position: fixed;
		top: 100px;
		z-index: $z-index-navigation;
	}
}

.search__field {
	flex: 1;

	@include media-breakpoint-down(sm) {
		flex: none;
	}
}

.search__field__inner {
	position: relative;
	display: flex;
	align-items: center;
}

.search__field__clear {
	position: absolute;
	right: 0.5rem;
	color: $color-text-tertiary;
}

.search__label {
	@include font-size($font-size-body-xs);
	font-weight: $font-weight-medium;
	position: absolute;
	z-index: 1;
	top: 1.25rem;
	left: 1.5rem;
}

.search__input {
	@include font-size($font-size-body-md);
	margin: $card-base-padding;
	padding: 2rem 1.25rem 1rem 1.25rem;
	border: none;
	outline: none;
	appearance: none;
	border-radius: $border-radius-card;
	width: 100%;

	&:focus {
		outline: $focus-ring-thickness solid $color-accent;
	}

	&:hover {
		background-color: $color-background-level2;
	}
}

.search__divider {
	width: 1px;
	height: 2rem;
	background: $color-border;

	@include media-breakpoint-down(sm) {
		width: auto;
		height: 1px;
		margin: 0 1.5rem;
	}
}

.search__button {
	margin: 0 0.75rem;

	@include media-breakpoint-down(sm) {
		margin: 0.25rem 0.75rem 0.75rem;
		justify-content: center;
	}
}

.search__autocomplete {
	position: absolute;
	top: 100%;
	left: 0;
	margin-top: 0.5rem;
	background: $color-background-floating;
	border-radius: $border-radius-card + $card-base-padding;
	width: $search-field-width;
	text-align: left;
	box-shadow: $shadow-lg;
	overflow: hidden;

	max-height: 21rem;
	overflow: auto;

	@include media-breakpoint-down(sm) {
		width: 100%;
		position: relative;
		top: 0;
		left: 0;
	}

	.search__field:not(:focus-within) & {
		visibility: hidden;
		transform: translateY(-1rem);
		opacity: 0;
		transition: all 0.2s ease;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.search__field:focus-within & {
		visibility: visible;
		transform: translateY(0);
		opacity: 1;
		transition: all 0.35s $ease-out-cubic;

		@include media-breakpoint-down(sm) {
			display: block;
		}
	}
}

.search__autocomplete__list {
	list-style: none;
	margin: 0;
	padding: 0.75rem 0.75rem 0;
}

.search__autocomplete__listitem {
	.badge {
		font-weight: $font-weight-regular;
		color: $color-text-secondary;
	}
}

.search__autocomplete__grid {
	list-style: none;
	margin: 0;
	padding: 0.75rem 1.5rem 1.5rem 1.5rem;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 0.5rem;
	grid-row-gap: 0.5rem;
}

.search__autocomplete__griditem {
	.search__autocomplete__link {
		justify-content: flex-end;
		align-content: flex-end;
		padding: 0.75rem;
		background-color: $color-background-level2;
		height: 5rem;

		&:hover {
			background-color: $color-background-level3;
		}
	}
}

.search__autocomplete__divider {
	margin: 0;
	padding: 1.5rem 1.5rem 0 1.5rem;
}

.search__autocomplete__footer {
	margin-top: 0.75rem;
	padding: 1rem 1.5rem;
	background: $color-background-level2;
	color: $color-text-secondary;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include media-breakpoint-down(sm) {
		display: none;
	}
}
