@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.value-prop {
	margin: 10rem auto;

	@include media-breakpoint-down(md) {
		margin: 5rem auto;
	}
}

.value-prop__title {
	margin: 0 auto;
	max-width: 40rem;
	text-align: center;
}

.value-prop__subtitle {
	margin: 0 auto;
	max-width: 30rem;
	text-align: center;
}

.value-prop__cards {
	display: flex;
	gap: 0.75rem;
	width: 100%;

	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
	}

	@include media-breakpoint-down(sm) {
		flex-direction: column;
	}
}

.value-prop__card {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1.25rem;
	min-height: 16rem;

	@include media-breakpoint-down(md) {
		padding: 1rem;
	}

	@include media-breakpoint-down(sm) {
		min-height: 12rem;
	}
}
