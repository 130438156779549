@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.link {
	@include float-on-hover();
	text-decoration: none;
	font-weight: $font-weight-medium;
	color: $color-accent;

	&:hover {
		text-decoration: underline;
		//text-underline-offset: 0.1em;
		text-underline-position: under;
	}

	&:active {
		opacity: 0.5;
	}
}
