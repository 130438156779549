@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.faq {
	margin: 10rem auto;
}

.faq__title {
	margin: 0 auto;
	text-align: center;
}
