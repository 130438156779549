@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.image-grid {
	overflow: auto;
}

.image-grid__inner {
	display: flex;
	gap: 0.75rem;
	height: 24rem;

	@include media-breakpoint-down(md) {
		height: 20rem;
		width: auto;
		max-width: none;
	}
}

.image-grid__image {
	object-fit: cover;
	border-radius: $border-radius-card;
	background: $color-background-level2;
	flex: auto;
	display: block;
	min-height: 10rem;
	flex: 1;
}

.image-grid__featured {
	flex: 1;
	position: relative;
	height: 100%;

	.image-grid__image {
		width: 100%;
		height: 100%;
	}

	.button {
		position: absolute;
		bottom: 1.5rem;
		left: 1.5rem;
	}

	@include media-breakpoint-down(md) {
		flex: none;
		width: 80dvw;
	}
}

.image-grid__images {
	height: 100%;
	aspect-ratio: 1;
	gap: 0.75rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		aspect-ratio: auto;
		flex-wrap: nowrap;
		flex-direction: row;
		overflow: visible;
	}

	.image-grid__image {
		aspect-ratio: 1;

		@include media-breakpoint-down(md) {
			aspect-ratio: auto;
			flex: none;
			width: 80dvw;
		}
	}
}

.image-grid__modal {
	width: 100dvw;
	height: 100dvh;
	position: relative;
	background: transparent;
	border: none;
	outline: none;
	border-radius: $border-radius-card;

	&::backdrop {
		background-color: rgba(0, 0, 0, 0.8);
	}
}

.image-grid__modal__images {
	width: 100%;
	height: 100%;
	overflow: auto;
	padding: 0 1.5rem;
	display: flex;
	border-radius: $border-radius-card;
	gap: 1rem;

	.image-grid__image {
		width: 80%;
		height: 100%;
		flex: none;
	}
}

.image-grid__modal__close {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
}
