@font-face {
	font-family: "Geist";
	font-style: normal;
	font-weight: 600;
	src: url("../../fonts/Geist-SemiBold.woff2") format("woff2");
}

@font-face {
	font-family: "Geist";
	font-style: normal;
	font-weight: 500;
	src: url("../../fonts/Geist-Medium.woff2") format("woff2");
}

@font-face {
	font-family: "Geist";
	font-style: normal;
	font-weight: 400;
	src: url("../../fonts/Geist-Regular.woff2") format("woff2");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	src: url("../../fonts/Inter-SemiBold.woff2") format("woff2");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	src: url("../../fonts/Inter-Medium.woff2") format("woff2");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	src: url("../../fonts/Inter-Regular.woff2") format("woff2");
}
