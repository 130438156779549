@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.list__item {
	display: block;
}

.list__item__button {
	@include focus-ring($border-radius-card * 0.5);
	color: $color-text;
	text-decoration: none;
	border-radius: $border-radius-card * 0.5;
	background: transparent;
	border: none;
	appearance: none;
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: left;
	padding: 0.375rem 0.75rem;

	&:hover {
		background-color: $color-background-level2;
	}

	&:active {
		filter: brightness(0.8);
	}

	.badge {
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
	}
}

.list__divider {
	margin: 0;
	padding: 1.5rem 0.75rem 0.25rem;
}
