@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.home__hero {
	color: $color-white;

	.hero__container {
		gap: 2.5rem;
	}
}

.home__treatments {
	padding: 6rem 0;
	text-align: center;

	@include media-breakpoint-down(md) {
		padding: 4rem 0;
		text-align: center;
	}
}

.home__treatments .tabs {
	@include media-breakpoint-down(md) {
		display: flex;
		flex-wrap: wrap;
		gap: 0;

		.tab {
			flex-basis: 50%;
		}
	}
}

.home__treatments__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3rem;

	@include media-breakpoint-down(md) {
		gap: 2rem;
	}
}

.home__destinations {
	padding: $main-section-padding;
	text-align: center;
	margin-top: 8rem;

	@include media-breakpoint-down(md) {
		margin-top: 4rem;
	}
}

.home__destinations__inner {
	background-color: $color-background-level2;
	background-image: url("./../../images/atlas-background.svg");
	background-size: cover;
	border-radius: $border-radius-section;
	width: 100%;
	padding: 10rem 0 15rem;

	@include media-breakpoint-down(md) {
		padding: 6rem 0 4rem;
	}
}

.home__destinations__title {
	width: 100%;
	max-width: 50rem;
	margin: 0 auto 6rem;

	@include media-breakpoint-down(md) {
		margin: 0 auto 2rem;
	}
}
