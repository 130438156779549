@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.carousel {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
}

.carousel__previous,
.carousel__next {
	position: absolute;
	margin: 0.5rem;
	transition: all 0.35s ease;

	&:disabled {
		opacity: 0;
		transform: scale(0.5);
	}

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.carousel::before {
	content: "";
	position: absolute;
	background-color: rgba(0, 0, 0, 0);
	left: -$main-section-padding;
	right: -$main-section-padding;
	bottom: calc(1.5rem - $main-section-padding);
	top: calc(1.5rem - $main-section-padding);
	backdrop-filter: blur(20px);
	border-radius: $border-radius-card + $main-section-padding;
}

.carousel__previous {
	right: 100%;
}

.carousel__next {
	left: 100%;
}

.carousel__mask {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	overflow: auto;
	padding: 1.5rem 0;
	position: relative;
	mask-image: linear-gradient(
		to right,
		white 0%,
		white 10%,
		white 90%,
		white 100%
	);
	transition: all 0.25s ease;
}

.carousel__mask--end {
	mask-image: linear-gradient(
		to right,
		white 0%,
		white 10%,
		white 90%,
		transparent 100%
	);
}

.carousel__mask--start {
	mask-image: linear-gradient(
		to right,
		transparent 0%,
		white 10%,
		white 90%,
		white 100%
	);
}

.carousel__mask--both {
	mask-image: linear-gradient(
		to right,
		transparent 0%,
		white 10%,
		white 90%,
		transparent 100%
	);
}

.carousel__items {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	gap: 0.5rem;
	margin: auto;
}
