@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.tag {
	@include font-size($font-size-body-md);
	color: $color-text;
	padding: 0.25rem 0.5rem;
	border-radius: $border-radius-tag;
	background: $color-background-level2;
}

.tag--sm {
	@include font-size($font-size-body-sm);
	padding: 0.175rem 0.475rem;
}
