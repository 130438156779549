@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;
@use "card" as *;

.tabs {
	display: flex;
	gap: 0.25rem;
	max-width: 100%;
	overflow: auto;
}

.tab {
	@include focus-ring($border-radius-tag);
	@include float-on-hover();

	&:active {
		filter: brightness(0.9);
	}
}

.tab--tag {
	appearance: none;
	background: none;
	border: none;
	outline: none;
	padding: 0.375rem 0.75rem;
	border-radius: $border-radius-tag;

	&:hover {
		background: $color-background-level2;
	}

	&.tab--selected {
		background: $color-background-level1;
	}
}

.tab--card {
	@extend .card--interactive;
	@include focus-ring($border-radius-card);

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	appearance: none;
	background: none;
	border: none;
	outline: none;
	border-radius: $border-radius-card;

	font-weight: $font-weight-semibold;
	width: 9rem;
	height: 7.5rem;
	padding: 1rem;
	text-align: left;
	background: $color-background;
	border: 2px solid transparent;

	@include media-breakpoint-down(md) {
		width: 8rem;
		height: 6rem;
	}

	&:hover {
		background: $color-background-level1;
		box-shadow: $shadow-sm;
	}

	&.tab--transparent {
		color: $color-white;
		background: $color-blur;
		backdrop-filter: blur(40px);
	}

	&.tab--selected {
		color: $color-text;
		background: $color-background;
		border-color: $color-text;
	}
}
