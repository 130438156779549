@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

@mixin container-padding($fs-map, $fs-breakpoints: $grid-breakpoints) {
	@each $fs-breakpoint, $fs-padding in $fs-map {
		@if $fs-breakpoint == null {
			padding-left: $fs-padding;
			padding-right: $fs-padding;
		} @else {
			// If $fs-font-size is a key that exists in
			// $fs-breakpoints, use the value
			@if map-has-key($fs-breakpoints, $fs-breakpoint) {
				$fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
			}
			@media screen and (min-width: $fs-breakpoint) {
				padding-left: $fs-padding;
				padding-right: $fs-padding;
			}
		}
	}
}

.container {
	@include container-padding($container-padding);
	width: 100%;
	max-width: $container-max-width;
	margin-right: auto;
	margin-left: auto;
}

.container--fluid {
	max-width: none;
}

.container--lg {
	max-width: $container-lg-max-width;
}
