@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.clinic-toolbar {
	padding-top: $navigation-height;
	margin-bottom: 1.75rem;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include media-breakpoint-down(md) {
		padding-top: $navigation-height-sm;
		margin-bottom: 1rem;
	}
}

.clinic-toolbar__actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
}

.clinic__title {
	margin: 4rem auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 40rem;
	text-align: center;
	gap: 1rem;

	@include media-breakpoint-down(md) {
		margin: 3rem auto 2rem;
	}
}

.clinic__content {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 4rem;

	@include media-breakpoint-down(md) {
		flex-direction: column;
		gap: 2rem;
	}
}

.clinic__body {
	flex: auto;
	max-width: 35rem;

	@include media-breakpoint-down(md) {
		max-width: none;
	}
}

.clinic__body__section {
	margin-top: 3rem;

	@include media-breakpoint-down(md) {
		margin-top: 2rem;
	}
}

.clinic__body__title {
	margin-bottom: 1.25rem;

	@include media-breakpoint-down(md) {
		margin-bottom: 0.75rem;
	}
}

.clinic__website,
.clinic__languages,
.clinic__highlight,
.clinic__highlight--featured,
.clinic__accreditation {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	margin-bottom: 0.5rem;

	svg {
		color: $color-text-tertiary;
	}
}

.clinic__highlight--featured,
.clinic__accreditation {
	padding: 1rem 0;
	margin: 0;
	border-top: 1px solid $color-border;

	&:last-child {
		border-bottom: 1px solid $color-border;
		margin-bottom: 1.5rem;
	}
}

.clinic__accreditation {
	align-items: flex-start;
}

.clinic__ratings {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	margin-bottom: 6rem;

	@include media-breakpoint-down(md) {
		margin-bottom: 2rem;
	}
}

.clinic__panel {
	flex: auto;
	max-width: 24rem;
	position: sticky;
	top: $navigation-height + 1rem;

	@include media-breakpoint-down(md) {
		position: relative;
		top: 0;
		order: -1;
		max-width: none;
		width: 100%;
		box-shadow: none;
	}
}

.clinic__map {
	padding: $card-base-padding;
	display: flex;
	align-items: center;
	justify-content: center;
}

.clinic__map__image {
	width: 100%;
	aspect-ratio: 1.75;
	border-radius: $border-radius-card-interior;
	background: $color-background-level2;
}

.leaflet-div-icon {
	border: none;
	background: none;
}

.leaflet-attribution-flag {
	display: none !important;
}

.clinic__map__pin {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: -1px;
	height: 5rem;
	margin-top: -4rem;
}

.clinic__map__pin__box {
	width: 3rem !important;
	height: 3rem !important;
	border-radius: 0.5rem;
	border: 3px solid $color-background-floating;
	box-shadow: 0 0 0 2px $color-accent;
	flex: none;
	object-fit: cover;
}

.clinic__map__pin__line {
	width: 2px;
	background: $color-accent;
	flex: auto;
}

.clinic__map__pin__dot {
	flex: none;
	width: 1rem;
	height: 1rem;
	border: 0.375rem solid $color-accent;
	border-radius: 50%;
	background: $color-white;
}

.clinic__treatments {
	padding: 0 0.75rem 2rem;
}

.clinic__treatments__item {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;

	.badge {
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
	}
}

.clinic__treatment__info {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	width: 100%;
	text-align: left;
	padding: 0.375rem 0.75rem;
}

.clinic__treatment__price {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.5rem 0.5rem 0;
	margin: 1.5rem 0.75rem 0;

	border-top: 1px solid $color-border;

	.button {
		margin-top: 1rem;

		@include media-breakpoint-down(md) {
			width: 100%;
			text-align: center;
			justify-content: center;
		}
	}
}

.clinic__treatment__price__value {
	margin: 0;
}
