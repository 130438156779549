@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.treatments-grid {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3rem;
	width: 100%;
}

.treatments-grid__inner {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 0.5rem;
}

.treatment-card {
	appearance: none;
	color: $color-text;
	text-decoration: none;
	min-width: 15rem;
	flex: 1;

	padding: 1.25rem;
	gap: 0.5rem;
	justify-content: space-between;
	height: 20rem;

	@include media-breakpoint-down(md) {
		padding: 1rem;
		height: 15rem;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
		height: 12rem;
	}
}

.treatment-card__top,
.treatment-card__bottom {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.5rem;
	padding-right: 2rem;
}

.treatment-card__bottom {
	@include font-size($font-size-body-sm);
	color: $color-text-secondary;
}

.treatment-card__countries {
	display: flex;
	gap: 0.25rem;
}
