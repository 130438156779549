@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.results {
	padding-top: $navigation-height;

	.search {
		margin-bottom: 2rem;
	}

	@include media-breakpoint-down(md) {
		padding-top: $navigation-height-sm;
	}

	@include media-breakpoint-down(sm) {
		.search {
			margin-bottom: 0;
		}
	}
}
