@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.accordion {
	@include focus-ring($border-radius-card);
	overflow: hidden;
	margin-bottom: 0.75rem;
}

.accordion__header {
	padding: 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	appearance: none;
	background: none;
	border: none;
	width: 100%;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: 1rem;
	}
}

.accordion__content {
	transition: all 0.4s $ease-in-out-cubic;
	opacity: 0;

	.accordion--open & {
		opacity: 1;
	}
}

.accordion__inner {
	padding: 1.5rem;
	border-top: 1px solid $color-border;
}

.accordion__chevron {
	transition: all 0.4s $ease-in-out-cubic;

	.accordion--open & {
		transform: rotate(180deg);
	}
}
