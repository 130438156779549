@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;
@use "../components/button" as *;

.navigation {
	width: 100%;
	height: $navigation-height;
	padding: $main-section-padding * 2;
	color: $color-text;
	position: fixed;
	display: flex;
	align-items: stretch;
	z-index: $z-index-navigation;
	transition: all 0.2s ease;
}

.navigation--dark {
	color: $color-white;
}

.navigation--scrolled {
	color: $color-text;

	.navigation__inner {
		background: $color-background-floating;
		box-shadow: $shadow-lg;
	}
}

.navigation__inner {
	display: flex;
	width: 100%;
	border-radius: $border-radius-card;
	align-items: stretch;
	padding: 0.5rem;
	transition: all 0.2s ease;
}

.navigation__leading {
	display: flex;
	align-items: stretch;
	flex: 1;
}

.navigation__menu {
	flex: 2;
}

.navigation__actions {
	flex: 1;
}

.navigation__menu,
.navigation__actions {
	display: flex;
	list-style-type: none;
	padding: 0 1rem;
	margin: 0;
	align-items: center;
}

.navigation__menu {
	justify-content: center;
}

.navigation__menu__list {
	display: flex;
}

.navigation__item {
	display: flex;
	justify-content: center;
	position: relative;
}

.navigation__item__link {
	@include media-breakpoint-up(md) {
		@include font-size($font-size-body-md);
		@include focus-ring($border-radius-tag);
		display: block;
		line-height: $line-height-body;
		font-weight: $font-weight-medium;
		padding: 0.5rem 0.75rem;
		border-radius: $border-radius-tag;
		text-decoration: none;
		transition: all 0.25s ease;
		opacity: 0.75;
		position: relative;
		color: inherit;
		transition: transform 0.15s ease;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: currentColor;
			opacity: 0;
			border-radius: $border-radius-tag;
		}

		.navigation__item--active & {
			backdrop-filter: blur(16px);

			&::after {
				opacity: 0.05;
			}
		}

		&:hover {
			opacity: 1;
			backdrop-filter: blur(16px);
			transform: translateY(-2px);

			&::after {
				opacity: 0.1;
			}
		}

		&:active {
			transition: none;
			transform: translateY(-1px);
			filter: brightness(0.8);
		}
	}
}

.navigation__submenu {
	@include media-breakpoint-up(md) {
		width: 20rem;
		max-height: 25rem;
		overflow: auto;
		padding: 1.25rem 0.75rem;
		position: absolute;
		top: 100%;
		border-radius: $border-radius-card;
		box-shadow: $shadow-lg;
		background: $color-background-floating;

		opacity: 0;
		visibility: hidden;
		transform-origin: top;
		transform: translate3d(0, -0.5rem, 0) scale(0.98);
		transition: all 0.2s ease;

		.navigation__item:hover &,
		.navigation__item:focus-within & {
			visibility: visible;
			opacity: 1;
			transform: translate3d(0, 0, 0) scale(1);
			transition: all 0.5s $ease-out-quint;
		}

		.list__divider:first-of-type {
			padding-top: 0;
		}
	}
}

.navigation__submenu__list {
	padding-top: 1rem;

	&:first-of-type {
		padding-top: 0;
	}
}

.navigation__hamburger {
	display: none;
}

.navigation__logo {
	display: flex;
	color: inherit;
	align-items: center;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	.logo {
		height: 1rem;
	}
}

.navigation__mobile__close {
	display: none;
}

.navigation__actions {
	justify-content: flex-end;
}

.navigation__action {
	margin-left: 0.5rem;

	> .button {
		color: currentColor;
		box-shadow: none;
		background: none;

		&:hover {
			box-shadow: none;
			&::after {
				opacity: 0.5;
			}
		}

		&:active {
			filter: brightness(0.9);
			transform: translateY(-1px);
			transition: none;
		}

		&::after {
			@extend .button--sm;
			@include media-breakpoint-up(md) {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: $border-width-default solid currentColor;
				opacity: 0.25;
			}
		}
	}
}

.comparison-cart__button {
	&.button--icon.button--sm {
		padding: 0.5rem 0.75rem;
		width: auto;
		min-width: $button-size-sm;

		&::after {
			width: 100%;
		}
	}

	svg {
		margin: 0;
	}

	&.button::after {
		width: 100%;
	}

	&.comparison-cart--active {
		background: currentColor;
		svg {
			color: $color-white;
			mix-blend-mode: difference;
		}
	}
}

.comparison-cart__count {
	@include font-size($font-size-body-xs);
	font-weight: $font-weight-semibold;
	font-family: $font-family-heading;
	width: 2rem;
	height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $color-white;
	mix-blend-mode: difference;
	position: relative;
	padding-right: 0.5rem;
	transition: all 0.5s ease;
	overflow: hidden;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 1.5rem;
		height: 1.5rem;
		background: currentColor;
		opacity: 0.25;
		border-radius: 50%;
	}

	:not(.comparison-cart--active) > & {
		opacity: 0;
		padding: 0;
		width: 0;
		transform: scale(0.5);
	}
}

.comparison-cart__toast {
	position: absolute;
	top: 100%;
	right: 0;
	margin: $main-section-padding * 2;
	display: flex;
	align-items: center;
	background: $color-text;
	color: $color-text-inverted;
	padding: 1.25rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
	max-width: 22rem;
	border-radius: $border-radius-card;
	box-shadow: $shadow-lg, $shadow-lg, $shadow-md;

	.button {
		color: $color-text-inverted;
		mix-blend-mode: difference;
		opacity: 0.5;
	}
}

.comparison-cart__toast__content {
	flex: 1;
}

.comparison-cart__toast__subline {
	opacity: 0.5;
}

.comparison-cart__toast__image {
	width: 2.5rem;
	height: 2.5rem;
	background-color: $color-background-level3;
	border-radius: $border-radius-card * 0.5;
	margin-right: 0.75rem;
}

@include media-breakpoint-down(md) {
	.navigation {
		height: $navigation-height-sm;
	}

	.navigation__hamburger {
		display: block;
		margin: 0;
	}

	.navigation__menu {
		order: 1;
		flex: 1;
		justify-content: flex-start;
	}

	.navigation__actions,
	.navigation__menu {
		padding: 0;
	}

	.navigation__actions {
		flex: 1;
		order: 3;
		z-index: $z-index-navigation + 1;
	}

	.navigation__leading {
		justify-content: center;
		flex: 1;
		order: 2;
		z-index: $z-index-navigation + 1;
	}

	.comparison-cart__toast {
		position: fixed;
		top: auto;
		bottom: 0;
	}

	.navigation__menu__list {
		position: fixed;
		top: 0;
		left: 0;
		z-index: $z-index-navigation;
		width: calc(100dvw - $main-section-padding * 2);
		max-height: calc(100dvh - $main-section-padding * 2);
		margin: $main-section-padding;
		padding: 0.5rem 0.5rem 1.5rem;
		background-color: $color-background;
		border-radius: $border-radius-card;
		box-shadow: $shadow-lg, $shadow-lg;
		visibility: hidden;
		opacity: 0;
		transform: translate3d(0, -100%, 0);
		transition: all 0.2s ease;
		overflow: auto;
		flex-direction: column;

		&.menu-mobile--visible {
			visibility: visible;
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition: all 0.5s $ease-in-out-cubic, opacity 0.25s ease;
		}
	}
	.navigation__item {
		display: block;
	}

	.navigation__menu .navigation__item__link {
		@include font-size($font-size-h4);
		line-height: $line-height-h4;
		font-weight: $font-weight-semibold;
		letter-spacing: $letter-spacing-heading-tight;
		color: $color-text;
		margin-top: 1.5rem;
		padding: 0 0.5rem;
	}

	.list__divider {
		padding-top: 0;
	}

	.navigation__submenu__list:first-of-type {
		padding-top: 1rem;
	}

	.navigation__submenu {
		width: 100%;
	}

	.navigation__mobile__close {
		display: flex;
		margin: 0.25rem 0.25rem 0;
		position: sticky;
		top: 0;
		z-index: 1;
	}
}
