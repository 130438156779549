@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.review-card__interactive {
	padding: 1.5rem;
	width: 100%;
}

.review-card__header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 1rem;
	width: 100%;
}

.review-card__text {
	margin-bottom: 1rem;
	max-height: 4.5rem;
	text-overflow: ellipsis;
	overflow: hidden;
}

.star {
	color: $color-text-secondary;
	stroke: currentColor;
}

.star--filled {
	fill: currentColor;
}

.review-card__footer {
	.badge {
		font-size: inherit;
		line-height: inherit;
	}
}
