@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.hero {
	width: 100%;
	height: 90dvh;
	padding: $main-section-padding;
	position: relative;

	@include media-breakpoint-up(md) {
		.carousel {
			position: absolute;
			bottom: 0;
		}
	}

	@include media-breakpoint-down(md) {
		height: 70dvh;
	}
}

.hero--full {
	height: 100dvh;
}

.hero--image {
	color: $color-white;

	.hero__inner::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: $border-radius-section;
		background: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0) 50%
		);
	}
}

.hero__inner {
	background-color: $color-background-level2;
	background-position: center;
	border-radius: $border-radius-section;
	width: 100%;
	height: 100%;
	background-size: cover;
	position: relative;
	display: flex;
}
.hero__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: $border-radius-section;
	z-index: 0;
}

.hero__tint {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: $border-radius-section;
	mix-blend-mode: soft-light;
	z-index: 2;
	pointer-events: none;
}

.hero__container {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	height: 100%;
	justify-content: center;
	position: relative;
	padding-bottom: 2rem;
	padding-top: 2rem;

	@include media-breakpoint-down(md) {
		justify-content: flex-end;
	}
}
