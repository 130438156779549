@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.banner__inner {
	border-radius: $border-radius-section;
	color: $color-white;
	min-height: 80dvh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	padding: 10rem 6rem;
	text-align: center;
	background-size: cover;

	@include media-breakpoint-down(md) {
		padding: 6rem 2rem;
	}
}

.banner__title {
	width: 100%;
	max-width: 30rem;
	margin-bottom: 2rem;
}

.banner__body {
	width: 100%;
	max-width: 40rem;
}
