/* Color */
$color-white: #ffffff;
$color-black: #000000;

$color-text: #1e1e1e;
$color-text-secondary: #757575;
$color-text-tertiary: #a9b1bf;
$color-text-inverted: #ffffff;

$color-background: #ffffff;
$color-background-level1: #f5f6f9;
$color-background-level2: #ebedf2;
$color-background-level3: #d5d7de;
$color-background-floating: #ffffff;

$color-card-base: #ffffff;
$color-card-highlight: #ffffff;

$color-blur: rgba(0, 0, 0, 0.5);

$color-accent: #51617b;
$color-accent-faded: rgba($color-accent, 0.15);

$color-positive: #02542d;
$color-positive-faded: rgba($color-positive, 0.15);

$color-negative: #02542d;
$color-negative-faded: rgba($color-positive, 0.15);

$color-border: rgba(0, 0, 0, 0.15);
$color-border-strong: rgba(0, 0, 0, 0.5);
$color-border-light: rgba(0, 0, 0, 0.05);

/* Spacings */

$button-size-md: 3.5rem;
$button-size-sm: 2.75rem;
$button-size-xs: 2rem;

$card-base-padding: 0.25rem;
$main-section-padding: 0.25rem;

$border-width-default: 1px;
$border-width-emphasized: 2px;

$border-radius-section: 1.5rem;
$border-radius-card: 0.75rem;
$border-radius-tag: 0.475rem;
$border-radius-card-interior: $border-radius-card - $card-base-padding;
$border-radius-button-md: $button-size-md * 0.5;
$border-radius-button-sm: $button-size-sm * 0.5;
$border-radius-button-xs: $button-size-xs * 0.5;

$focus-ring-offset: 0.25rem;
$focus-ring-thickness: 0.125rem;

$search-field-width: 40rem;
$navigation-height: 6.25rem;
$navigation-height-sm: 5rem;

$container-max-width: 1160px;
$container-lg-max-width: 1440px;
$container-padding: (
	null: 1rem,
	md: 1.5rem,
);

/* Font Family */

$font-family-heading: "Geist", sans-serif;
$font-family-body: "Inter", sans-serif;

/* Font Size */
$font-size-h1: (
	null: 4rem,
	lg: 8rem,
);

$font-size-h2: (
	null: 2.25rem,
	sm: 2.75rem,
	lg: 4.5rem,
);

$font-size-h3: (
	null: 2rem,
	lg: 2.5rem,
);

$font-size-h4: (
	null: 1.5rem,
	lg: 2rem,
);

$font-size-h5: (
	null: 1.25rem,
	lg: 1.5rem,
);

$font-size-body-lg: (
	null: 1rem,
	lg: 1.25rem,
);

$font-size-body-md: (
	null: 1rem,
	lg: 1rem,
);

$font-size-body-sm: (
	null: 0.75rem,
	lg: 0.875rem,
);

$font-size-body-xs: (
	null: 0.75rem,
);

$font-size-body-xxs: (
	null: 0.625rem,
);

/* Line height */

$line-height-h1: 0.8;
$line-height-h2: 0.8;
$line-height-h3: 0.9;
$line-height-h4: 1;
$line-height-h5: 1.1;
$line-height-body-lg: 1.2;
$line-height-body: 1.4;

/* letter spacing */

$letter-spacing-heading-tight: -0.05em;
$letter-spacing-heading-loose: -0.02em;
$letter-spacing-body: -0.01em;

/* Font weight */
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

/* Shadows */
$shadow-sm: 0 4px 10px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
$shadow-md: 0 8px 20px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
$shadow-lg: 0 16px 40px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);

/* Transitions */
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-out-back-smooth: cubic-bezier(
	0.3,
	1.65,
	0.4,
	1
); // Custom smoother transition
$ease-out-back-hard: cubic-bezier(0.3, 1.65, 0.7, 1.65);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

/* Z-Index */

$z-index-navigation: 10;
$z-index-search: 9;
$z-index-filters-mobile: 11;

/* Breakpoints */
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 800px,
	lg: 1100px,
	xl: 1400px,
);
