@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.comparison__container {
	height: 100dvh;
	display: flex;
	padding-top: $navigation-height;
	position: relative;
}

.comparison__toolbar {
	position: absolute;
	top: $navigation-height;
	left: 0;
	z-index: 1;
	margin: 1.5rem;
}

.comparison__empty {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.comparison-table {
	border: 1px solid $color-border;
	border-radius: $border-radius-section;
	overflow: auto;
	width: 100%;

	table {
		width: 100%;
	}

	td,
	th {
		background: $color-background;
		min-width: 20rem;
		box-shadow: inset -1px -1px 0 0 $color-border-light;

		@include media-breakpoint-down(md) {
			min-width: 50vw;
		}
	}

	td {
		padding: 1rem 1.5rem;
	}

	th {
		position: sticky;
		top: 0;
		padding: 1rem 1.5rem 1rem;
		height: 6rem;
		vertical-align: bottom;
	}

	td:first-child,
	th:first-child {
		background: $color-background-level1;
	}

	td:not(:first-child) {
		text-align: center;
	}

	tr:first-child > th:first-child,
	tr:first-child > td:first-child {
		border-top-left-radius: $border-radius-section;
	}

	tr:first-child > th:last-child,
	tr:first-child > td:last-child {
		border-top-right-radius: $border-radius-section;
	}

	tr:last-child > th:first-child,
	tr:last-child > td:first-child {
		border-bottom-left-radius: $border-radius-section;
	}

	tr:last-child > th:last-child,
	tr:last-child > td:last-child {
		border-bottom-right-radius: $border-radius-section;
	}
	.border-bottom--none {
		box-shadow: inset -1px 0 0 0 $color-border-light;
	}

	.comparison-table__clinic__header {
		background: $color-background;
	}

	.comparison-table__clinic__image {
		height: 13rem;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: stretch;

		img {
			border-radius: $border-radius-card;
			background: $color-background-level2;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@include media-breakpoint-down(md) {
			height: 8rem;
		}
	}
}
