@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.card {
	display: block;
	background: $color-card-base;
	border: $border-width-default solid $color-border;
	border-radius: $border-radius-card;
	text-align: left;
}

.card--interactive {
	@include focus-ring($border-radius-card);
	@include float-on-hover();
	box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	appearance: none;
	color: $color-text;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&:hover {
		border-color: $color-border-strong;
		box-shadow: $shadow-lg;
	}

	&:active {
		filter: brightness(0.9);
	}
}

.card--floating {
	background-color: $color-background-floating;
	box-shadow: $shadow-md;
}
