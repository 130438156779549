@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;

.footer {
	width: 100%;
	padding: $main-section-padding;
	position: relative;
	color: $color-accent;
	margin-top: 6rem;

	@include media-breakpoint-down(md) {
		margin-top: 3rem;
	}
}

.footer__inner {
	height: 32rem;
	background: $color-background-level2;
	border-radius: $border-radius-section;
	width: 100%;
	background-size: cover;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 2.5rem;

	@include media-breakpoint-down(md) {
		height: auto;
		padding: 1.5rem;
		flex-direction: column;
		align-items: flex-start;
		gap: 2rem;
	}
}

.footer__logo {
	display: block;
	height: 80%;
	color: inherit;
	opacity: 0.15;

	.logo {
		height: 100%;
	}

	@include media-breakpoint-down(md) {
		height: 10rem;
	}
}

.footer__list {
	width: 33%;

	@include media-breakpoint-down(md) {
		width: 100%;
	}

	li {
		margin-top: 0.5rem;
	}

	.footer__disclaimer {
		margin-top: 3rem;

		@include media-breakpoint-down(md) {
			margin-top: 1.5rem;
		}
	}
}
